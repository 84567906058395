<template>
  <div class="coupons-list">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="main-content">
      <topContent></topContent>
      <couponsList></couponsList>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import topContent from './cpns/top-content/top-content.vue'
import couponsList from './cpns/coupons-list/coupons-list.vue'

export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    topContent,
    couponsList
  },
  setup() {

    return {
      breadcrumbList
    }

  }
})
</script>

<style scoped lang="less">
.coupons-list {
  min-width: 1080px;

  .main-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;

    &:deep(.demo-tabs) {
      margin-top: 20px;
    }
  }
}
</style>




