<template>
  <div class="top-content">
    <div class="left">
      <el-button class="add-staff" type="primary" @click="handleAddClick">导出</el-button>
    </div>
    <div class="right">
      <el-select @clear="handleClear" clearable @change="handleTypeChange" v-model="ruleForm.status" class="m-2"
        placeholder="现金券类型" size="large">
        <el-option v-for="item in couponTypeList" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <div>
        <el-input @clear="handleClear" clearable v-model="ruleForm.phone" class="search" prefix-icon="search"
          placeholder="请输入用户手机号" />
      </div>

      <div class="search-wrap">
        <el-button class="add-staff" type="primary" @click="handleSearchClick">筛选</el-button>
      </div>
    </div>

  </div>
</template>

<script lang="js">
import { react } from '@babel/types';
import { defineComponent, reactive, ref } from 'vue'
import { couponTypeList } from '../../config'
import emitter from '@/utils/eventbus';
import { useRouter } from 'vue-router';

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {
    const router = useRouter()

    const ruleForm = reactive({
      status: 0
    })

    const handleSearchClick = () => {
      emitter.emit('refreshCashCouponDetailsList', ruleForm)
    }

    const handleAddClick = () => {
      emitter.emit('exportDetailList')
    }

    return {
      ruleForm,
      couponTypeList,
      handleSearchClick,
      handleAddClick
    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  display: flex;
  justify-content: space-between;

  &:deep(.el-select) {
    margin-right: 10px;
  }

  .left {}

  .right {
    display: flex;

    .search-wrap {
      margin-left: 10px;
    }
  }

}
</style>




