<template>
  <div class="coupons-list">
    <page-content
      :contentTableConfig="contentTableConfig"
      :isButtonStyle="true"
      :dataCount="dataCount"
      :dataList="dataList"
    >
      <template #userName="scope">
        <div class="info">
          <img v-if="scope.row.userAva" :src="scope.row.userAva" />
          <div>
            <p>{{ scope.row.userName }}</p>
            <p>{{ scope.row.userPhone }}</p>
          </div>
        </div>
      </template>
      
      <template #dpName="scope">
        {{ scope.row.dpName||'-' }}
      </template>

      <template #usedTime="scope">
        {{ scope.row.usedTime||'-' }}
      </template>

      <template #usedShopName="scope">
        {{ scope.row.usedShopName||'-' }}
      </template>

      <template #orderNo="scope">
        {{ scope.row.orderNo||'-' }}
      </template>

      <template #price="scope">
        {{ scope.row.price?'¥'+scope.row.price:'-' }}
      </template>

      <template #status="scope">
        <p v-if="scope.row.status==1">未使用</p>
        <p v-if="scope.row.status==2">已使用</p>
        <p v-if="scope.row.status==3">已过期</p>
        <p v-if="scope.row.status==4">已作废</p>
      </template>

      <template #handler="scope">
        <el-link :disabled="scope.row.status!=1" :underline="false" type="primary" @click.stop="handleDisableClick(scope.row)">停止用户用券</el-link>
      </template>
      <template #pagination>
        <el-pagination v-model:currentPage="queryInfo.page" v-model:page-size="queryInfo.pagesize"
          :page-sizes="[10, 20, 30, 40]" layout="total, sizes, prev, pager, next, jumper" :total="dataCount"
          @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </template>
    </page-content>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, onUnmounted ,watch,computed} from 'vue'
import { contentTableConfig } from './config'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { getUserCouponUsedList , stopUserCoupon } from '@/service/main/goods'
import emitter from '@/utils/eventbus'
import { useRouter,useRoute } from 'vue-router'
import { popup } from '@/utils/pop-up'
import { ElMessage } from 'element-plus'
import {exportMethod} from '@/utils/export'

export default defineComponent({
  props: {
  },
  components: {
    PageContent
  },
  setup(props) {
    const router = useRouter()
    const route = useRoute()

    const dataList = ref([])
    const dataCount = ref(0)
    const queryInfo = reactive({
      page: 1,
      pagesize: 10,
      status: 0
    })

    const handleSizeChange = pagesize => {
      queryInfo.pagesize = pagesize
    }

    const handleCurrentChange = page => {
      queryInfo.page = page
    }
    watch(queryInfo, () => {
      initPage()
    }, {
      deep: true
    })

    emitter.on('refreshCashCouponDetailsList', ruleForm => {
      queryInfo.status = ruleForm.status
      queryInfo.phone = ruleForm.phone
    })

    onUnmounted(() => {
      emitter.off('refreshCashCouponDetailsList')
    })

    //导出优惠券领取情况明细
    emitter.on('exportDetailList', () => {
      let params={
        current:queryInfo.page,
        size:queryInfo.pagesize,
        status:queryInfo.status==0?undefined:queryInfo.status,
        phone:queryInfo.phone,
        couponId:route.query.couponId
      }
      exportMethod('/coupon/web/downloadUserCouponUsed','优惠券领取情况明细',params)
    })

    onUnmounted(() => {
      emitter.off('exportDetailList')
    })


    //停止用户使用
    const handleDisableClick = (item) => {
      popup('确认停止用户使用该优惠券吗?', async() => {
        let res = await stopUserCoupon(item.id)
        if (res.code==0) {
          ElMessage.success({
            message: '操作成功!',
            type: 'success'
          })
          initPage()
        }
      })
    }

    const initPage = async () => {
      let params={
        current:queryInfo.page,
        size:queryInfo.pagesize,
        status:queryInfo.status==0?undefined:queryInfo.status,
        phone:queryInfo.phone,
        couponId:route.query.couponId
      }
      const res = await getUserCouponUsedList(params)
      let list = res.data.list
      dataList.value = list
      dataCount.value=Number(res.data.total)
    }
    initPage()

    return {
      dataList,
      dataCount,
      contentTableConfig,
      handleSizeChange,
      handleCurrentChange,
      queryInfo,
      handleDisableClick,
      exportMethod
    }

  }
})
</script>

<style scoped lang="less">
.coupons-list {
  margin-top: 20px;
  .info{
    display: flex;
    img{
      width: 45px;
      height: 45px;
      border-radius: 50%;
      margin-right: 10px;
    }
    p{
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .line {
    display: inline-block;
    margin: 0 6px;
  }
  .border{
    color: #ccc;
    padding: 0 6px;
  }
  .example-showcase .el-dropdown-link {
    cursor: pointer;
    color: var(--el-color-primary) !important;
    display: flex;
    align-items: center;
  }
}
</style>
