export const contentTableConfig = {
  propList: [
    { prop: 'userName', label: '用户信息', minWidth: '180', slotName: 'userName' },
    { prop: 'shopName', label: '用户归属门店', minWidth: '150' ,slotName: 'shopName'},
    { prop: 'createTime', label: '领取时间', minWidth: '150' },
    {
      prop: 'dpName',
      label: '领取门店',
      minWidth: '100',
      slotName: 'dpName'
    },
    {
      prop: 'usedTime',
      label: '使用时间',
      minWidth: '100',
      slotName: 'usedTime'
    },
    {
      prop: 'status',
      label: '状态',
      minWidth: '100',
      slotName: 'status'
    },
    {
      prop: 'usedShopName',
      label: '使用门店',
      minWidth: '120',
      slotName: 'usedShopName'
    },
    {
      prop: 'orderNo',
      label: '订单号',
      minWidth: '120',
      slotName: 'orderNo'
    },
    {
      prop: 'price',
      label: '消费金额',
      minWidth: '120',
      slotName: 'price'
    },
    { label: '操作', minWidth: '150', slotName: 'handler' }
  ],
  showIndexColumn: false,
  showSelectColumn: true
}
