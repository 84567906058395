export const breadcrumbList = [
  {
    name: '营销管理',
    path: ''
  },
  {
    name: '营销列表',
    path: '/main/marketing/list'
  },
  {
    name: '领取情况',
    path: ''
  }
]

export const couponTypeList = [
  {
    label: '全部',
    value: 0
  },
  {
    label: '未使用',
    value: 1
  },
  {
    label: '已使用',
    value: 2
  },
  {
    label: '已过期',
    value: 3
  },
  {
    label: '已作废',
    value: 4
  }
]
